//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HomePageCard from "@/frontend/components/page-sections/home-page-card.vue";

export default {
  name: 'Home',
  components: {HomePageCard},
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    }
  },
}
