//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'homePageCard',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    }
  },
}
